import React from 'react';
import Landing from './Landing';
import Features from './Features';
import Institutes from './Institutes';
import LifeStory from './LifeStory';
import News from './News';
import Reach from './Reach';
import Contact from './Contact';
import {buildType} from '../utils/constant'

import Funding from "./Funding";

import LockdownWarriors from './LockdownWarriors/LockdownWarrios'

export const Home = () => {

    return (
      <div>
        {/* <div style={{ position: "fixed", bottom: "10px", left: "30px", zIndex: "1", width: "60px", height: "60px", cursor: "pointer" }}>
                <a href="https://api.whatsapp.com/send?phone=917290085264&text=Hi Classplus, I would like to know more about your Complete Coaching Solution" target="_blank">
                    <img alt="" src={whatsappIcon} style={{ height: "60px", width: "60px" }} />
                </a>
            </div> */}
        <Landing />
        <Reach />
        <LockdownWarriors/>
        {/* {
          buildType === "tutor" && <Funding />
        } */}
        {/* {buildType === "tutor" && <LifeStory celebrity={true} />} */}
        <Features />
        <Institutes />

        {/* <News /> */}
        {/* {buildType === "tutor" && <LifeStory celebrity={false} />} */}
        {/* <Contact /> */}
      </div>
    );
}

export default Home