import React from 'react';
import baloon from "../images/baloon.png";
import Close from "../images/Close.svg";

export default function SuccessModal(props) {
    return(
        <div className="successModal">
            <div className="successModal__container">
                <div className="successModal__container--header">
                    <img className="successModal__container--header__img" src={Close} onClick={props.closeModal} alt="" />
                </div>
                <img className="successModal__container--img" src={baloon} alt="" />
                <div className="successModal__container--head">Thank You</div>
                <div className="successModal__container--para">{props.para}</div>
            </div>
        </div>
    )
}