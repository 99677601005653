import React from "react";
import FeaturesList from "./FeaturesList";
import Contact from "./Contact";
import CarouselVideo from "../images/Carousel-1.webp";
import CarouselVideoSmall from "../images/Carousel-1-small.webp";
import CSM from "../images/logos/CSM.webp";
import App from "../images/logos/APP-min.webp";
import Image from 'react-image-webp';

export const DetailedFeatures = () => {
  return (
    <div className="features" style={{ "margin-top": "5rem" }}>
      <div className="features__header">
        <div className="features__header--heading heading--mega">
          A few reasons why customers love us
        </div>
        <div className="features__header--content p--medium green--text">
          There are multiple utilities for a coaching institute. Our mission is
          to make each one of them possible through one single platform. Take a
          quick look at how we help institutes of all sizes grow bigger, grow
          faster!
        </div>
      </div>
      <div className="detailedFeatures">
        <div className="Carousel grid--2 marginTop7" id="videoportal">
          <div className="Carousel__pic">
            {/* <img
              src={CarouselVideo}
              alt="Carousel Mega"
              className="Carousel__pic--img"
            />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/Carousel-1.svg")}
              webp={require("../images/Carousel-1.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
          <div className="Carousel__content marginTop13">
            <div className="Carousel__content--heading heading--medium">
              Video Portal
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text">
                Reach out to thousands of students through your educational
                lectures and grow your brand in the fastest way possible. Host
                your video lectures securely and share access with only who you
                want to.{" "}
              </p>

              <p className="p--medium Carousel__content--content--text green--text marginTop3">
                In today's digital age, multimedia content attracts students the
                most. Securing and distributing such content is one of the
                biggest challenges of the visionary tutors today. We make this a
                seamless experience for them.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="Carousel grid--2 marginTop7 colRev">
          <div className="Carousel__content marginTop11">
            <div className="Carousel__content--heading heading--medium">
              Online Test Portal
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text">
                Now conducting online tests without the hassle of manually
                checking is possible! Give your students enough practice by
                using our online test portal on your mobile app and web portal.
                Automatic uploading of all your question papers.{" "}
              </p>

              <p className="p--medium Carousel__content--content--text green--text marginTop3">
                With all the exams going online, it is time that you deploy all
                your questions on an online portal so that your students can
                attempt it anytime and anywhere and you receive analytics of how
                your class is performing in your lessons.{" "}
              </p>
            </div>
          </div>

          <div className="Carousel__pic">
            {/* <img src={CSM} alt="Carousel Mega" className="Carousel__pic--img" />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/logos/CSM.svg")}
              webp={require("../images/logos/CSM.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
        </div>

        <div className="Carousel grid--2 marginTop7">
          <div className="Carousel__pic">
            {/* <img src={App} alt="Carousel Mega" className="Carousel__pic--img" />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/logos/APP-min.svg")}
              webp={require("../images/logos/APP-min.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
          <div className="Carousel__content marginTop13">
            <div className="Carousel__content--heading heading--medium">
              Your Coaching’s Mobile App
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text">
                One single mobile app by your coaching institute's name.
                Digitise all your academic activities through the platform and
                give your students, their parents the experience they desire!{" "}
              </p>

              <p className="p--medium Carousel__content--content--text green--text marginTop3">
                Host your coaching's mobile app on Playstore like all the
                leading education brands and use it to run all your classroom
                activities, parent interactions and sharing academic content.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="features__content">
        <FeaturesList />
      </div>
      <Contact />
    </div>
  );
};

export default DetailedFeatures;
