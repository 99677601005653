import React, { Component } from "react";
import close from "../images/Close.svg";

class VideoModal extends Component {
  handleYoutubeClick = () => {
    window.open('https://bit.ly/3C73E1y', '_blank')
  }
  
  render() {
    return (
      <div className="VideoModal">
        <div className="VideoModal__Container">
          <div className="VideoModal__Container--Head">
            {/* <p>Video</p> */}
            <img alt="close" onClick={this.props.toggleVideo} src={close} />
          </div>
          <div className="VideoModal__Container--Body">
            
            <img 
            width={window.screen.width > 600 ? '' : 300}
            src={ window.screen.width > 600 ?
              'https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/tutor/prod/images/unlock-2.jpg' :
              'https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/tutor/prod/images/WhatsApp+Image+2021-06-01+at+6.40.29+PM.jpeg'
              } onClick={this.handleYoutubeClick}/>
            {/* <video
              autoPlay
              style={{outline: 'none'}} 
              width="100%"
              poster="https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/static/img-Warriors.png"
              // height={window.screen.width > 700 ? "539":"250"} 
              controls
              >
                  <source src="https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/static/Thank+You+Classplus_Final+Clip.mp4" type="video/mp4" />
                  {'Your browser does not support the video tag.'}
            </video> */}

 
          {/*<iframe
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              src={`https://www.youtube.com/embed/${this.props.embedLink}`}
            ></iframe> */}
          </div>
        </div>
      </div>
    );
  }
}

export default VideoModal;
