import React from 'react';
import InstitutesList from  './InstitutesList';
import {familyCount} from '../utils/constant'

export const Institutes = () => {
    return (
        <div className="Institutes">
            <div className="Institutes--text">
            <div className="Institutes--heading heading--mega">
               The Classplus family is {familyCount}+ teachers strong 
            </div>
                <div className="Institutes--content p--medium green--text">
           Our customers are our foremost priority and we don't leave any efforts in making their lives awesome.
         Their love and support has made us what we are. We look forward to seeing you in the family and growing together. 
            </div>
            </div>
           <InstitutesList />
           </div>
    )
}

export default Institutes