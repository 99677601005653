import React, { Component } from "react";
import Image from "react-image-webp";
// import CarouselVideo from "../images/Carousel-1.webp";
// import CSM from "../images/logos/CSM.webp";
// import App from "../images/logos/APP-min.webp";
// import CarouselVideoSmall from "../images/Carousel-1-small.webp";
import SVGQuotes from "../images/SVGQuotes.svg";
import Slider from "react-slick";
// import AoA from "../images/logos/AoA-min.webp";
// import D2S from "../images/logos/D2S-min.webp";
// import Kaksha from "../images/logos/Kaksha4-min.webp";
import { Link } from "react-router-dom";

class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      pauseOnHover: true,
    };
    return (
      <Slider {...settings}>
        <div className="Carousel grid--2">
          <div className="Carousel__pic">
            {/* <img
              src={CarouselVideo}
              alt="Carousel Mega"
              className="Carousel__pic--img"
            />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/Carousel-1.svg")}
              webp={require("../images/Carousel-1.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
          <div className="Carousel__content">
            <div className="Carousel__content--heading heading--medium">
              Video Portal
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text p6rem">
                Reach out to thousands of students through your educational
                lectures and grow your brand in the fastest way possible. Host
                your video lectures securely and share access with only who you
                want to.{" "}
              </p>

              <button className="btn btn--bookADemo Carousel__content--content--button">
                <Link to="/features">Know more</Link>
              </button>
            </div>
            {/* <div className="Carousel__content--testimonial">
              <div className="Carousel__content--testimonial--pic">
                <img
                  src={AoA}
                  alt="Testimonial Pic"
                  className="Carousel__content--testimonial--pic--img"
                />
                <Image
                  className="Carousel__content--testimonial--pic--img"
                  src={require("../images/logos/AoA-min.png")}
                  webp={require("../images/logos/AoA-min.webp")}
                />
                <p className="Carousel__content--testimonial--pic--name">
                  Naresh Agarwal
                </p>
                <p className="Carousel__content--testimonial--pic--org">
                  Academy of Accounts (AoA)
                </p>
              </div>
              <div className="Carousel__content--testimonial--text p--small green--text fw500">
                <img
                  src={SVGQuotes}
                  alt="quotes"
                  className="Carousel__content--testimonial--text--quotes"
                />
                Uploading and sharing the videos through app has made the
                process of sharing study material easy. The task which I used to
                do individually through WhatsApp has now become streamlined.
              </div>
            </div> */}
          </div>
        </div>

        <div className="Carousel grid--2">
          <div className="Carousel__pic">
            {/* <img src={CSM} alt="Carousel Mega" className="Carousel__pic--img" />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/logos/CSM.svg")}
              webp={require("../images/logos/CSM.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
          <div className="Carousel__content">
            <div className="Carousel__content--heading heading--medium">
              Online Test Portal
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text p6rem">
                Conducting online tests without the hassle of checking is now
                possible! Give your students enough practice by using our online
                test portal. Automatically upload all your question papers.{" "}
              </p>

              <button className="btn btn--bookADemo Carousel__content--content--button">
                <Link to="/features">Know more</Link>
              </button>
            </div>
            {/* <div className="Carousel__content--testimonial">
              <div className="Carousel__content--testimonial--pic">
                <img
                  src={D2S}
                  alt="Testimonial Pic"
                  className="Carousel__content--testimonial--pic--img"
                />

                <Image
                  className="Carousel__content--testimonial--pic--img"
                  src={require("../images/logos/D2S-min.png")}
                  webp={require("../images/logos/D2S-min.webp")}
                />
                <p className="Carousel__content--testimonial--pic--name">
                  Deepak Chowdhary
                </p>
                <p className="Carousel__content--testimonial--pic--org">
                  D2S Campus
                </p>
              </div>
              <div className="Carousel__content--testimonial--text p--small green--text fw500">
                <img
                  src={SVGQuotes}
                  alt="quotes"
                  className="Carousel__content--testimonial--text--quotes"
                />
                Conducting online tests have become really easy. The automatic
                correction has made the task easier and simpler. Also, sending
                test reports to students and parents has become effortless.
              </div>
            </div> */}
          </div>
        </div>

        <div className="Carousel grid--2">
          <div className="Carousel__pic">
            {/* <img src={App} alt="Carousel Mega" className="Carousel__pic--img" />
            <img
              src={CarouselVideoSmall}
              alt="Carousel Mega"
              className="Carousel__pic--img--small"
            /> */}
            <Image
              className="Carousel__pic--img"
              src={require("../images/logos/APP-min.svg")}
              webp={require("../images/logos/APP-min.webp")}
            />
            <Image
              className="Carousel__pic--img--small"
              src={require("../images/Carousel-1-small.svg")}
              webp={require("../images/Carousel-1-small.webp")}
            />
          </div>
          <div className="Carousel__content">
            <div className="Carousel__content--heading heading--medium">
              Your Coaching’s Mobile App
            </div>
            <div className="Carousel__content--content">
              <p className="p--medium Carousel__content--content--text green--text p2rem">
                One single mobile app by your coaching institute's name that can
                help you in becoming a digital brand. Digitise all your academic
                activities through the platform and give your students and their
                parents with the experience they desire for!{" "}
              </p>

              <button className="btn btn--bookADemo Carousel__content--content--button">
                <Link to="/features">Know more</Link>
              </button>
            </div>
            {/* <div className="Carousel__content--testimonial">
              <div className="Carousel__content--testimonial--pic">
                <img
                  src={Kaksha}
                  alt="Testimonial Pic"
                  className="Carousel__content--testimonial--pic--img"
                />
                <Image
                  className="Carousel__content--testimonial--pic--img"
                  src={require("../images/logos/Kaksha4-min.png")}
                  webp={require("../images/logos/Kaksha4-min.webp")}
                />

                <p className="Carousel__content--testimonial--pic--name">
                  Sunit Kumar
                </p>
                <p className="Carousel__content--testimonial--pic--org">
                  Kaksha Classes
                </p>
              </div>
              <div className="Carousel__content--testimonial--text p--small green--text fw500">
                <img
                  src={SVGQuotes}
                  alt="quotes"
                  className="Carousel__content--testimonial--text--quotes"
                />
                We used to do everything manually. Be it sending messages to
                parents or informing students about extra classes. But, with the
                app, we elimated the manual work, and now, we are managing the
                coaching institute better.
              </div>
            </div> */}
          </div>
        </div>
      </Slider>
    );
  }
}

export default Carousel;
