import React from 'react'
import './Warriors.css'

function LockdownWarriors() {
    return (
        <div className="Warriors">
            <div className="Warriors__Top">Warriors of Lockdown 1.0</div>
            <div className="Warriors__Body">    
                <video
                autoPlay
                style={{outline: 'none'}} 
                width="100%"
                poster="https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/static/img-Warriors.png"
                height={window.screen.width > 700 ? "539":"250"} 
                controls
                >
                    <source src="https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/static/Thank+You+Classplus_Final+Clip.mp4" type="video/mp4" />
                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                    Your browser does not support the video tag.
                </video>


                {/* <iframe 
                width="100%" 
                height={window.screen.width > 700 ? "539":"250"} 
                src="https://www.youtube.com/embed/nFrFaUhNA3I" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe> */}
            </div>
        </div>
    )
}

export default LockdownWarriors
