import React, { Component } from 'react';
import Select from 'react-select';
import sgImgSmall from "../images/sgImgSmall.png";

class NewForm extends Component {
    constructor() {
        super();
        this.state = {
            name:"",
            mobile:"",
            city:"",
            identity:"",
            isIdentityError: false,
            isNameError: false,
            isMobileError: false,
            isCityError: false,
            activeIdentity : "",
            countryOptions: [],
            buttonEnable: false,
            identityArr : [
                {
                    name:"Tutor",
                },
                {
                    name:"Student",
                },
                {
                    name:"Parent",
                },
            ],
        }
    }

    componentDidMount() {
        this.handleStateApi();
    }

    submitDetails = () => {
        const {
            identity,
            mobile, 
            name, 
            city, 
        } = this.state;
        if(this.state.identity === "Tutor"){
            this.props.postDetails(identity,mobile,name,city);
            this.props.setStep(2,identity,mobile,name,city);
        }else{
            this.props.postDetails(identity,mobile,name,city)
            this.props.toggleSuccessModal();
            this.setState({identity:"",mobile:"",name:"",city:""});
        }
    }

    handleStateApi = () =>{
        fetch(
          "https://crm.classplus.co/su/affiliate/state?city=1",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            let countries = data.data.city;
            let countryOptions = countries.map((obj,ind) => {
              return {
                key: ind,
                label: obj.city,
              };
            });
            this.setState({ countryOptions: countryOptions });
          })
          .catch((err) => console.log(err.message));
      };

    setIdentity = (index) => {
        this.setState({activeIdentity:index},() => { 
            this.setState({identity:this.state.identityArr[this.state.activeIdentity].name},()=>{this.validation()});
        });
    }

    setName = (e) => {
        this.setState({name:e.target.value},()=>{this.validation()});
    }

    nameOnBlur = (e) => {
        if(e.target.value.length < 3){
            this.setState({isNameError:true},()=>{this.validation()});
        }else{
            this.setState({isNameError:false},()=>{this.validation()});
        }
    }

    setMobile = (e) => {
        if(e.target.value.length <= 10){
            this.setState({mobile: e.target.value},()=>{this.validation()});
        }
    }

    mobileOnBlur = (e) => {
        let mobileFormat = new RegExp(/^[6789]\d{9}$/);
        if(mobileFormat.test(e.target.value)){
            this.setState({isMobileError:false},()=>{this.validation()});
        }else{
            this.setState({isMobileError:true},()=>{this.validation()});
        }
    }

    setCity = city => {
        this.setState({ city:city.label },()=>{this.validation()});
    };

    validation = () => {
        const {
            name, 
            mobile, 
            city, 
            isNameError,
            isMobileError,
            isCityError,
            identity, 
            isIdentityError
        } = this.state;

        if(
            name !== "" && 
            mobile !== "" && 
            city !== "" && 
            identity !== "" &&
            !isNameError &&
            !isMobileError &&
            !isCityError &&
            !isIdentityError
        ){
            this.setState({buttonEnable: true});
        }else{
            this.setState({buttonEnable: false});
        }
    }

    render(){
        const { name, 
                mobile, 
                city, 
                isNameError,
                isMobileError,
                isCityError,
                identity, 
                identityArr, 
                activeIdentity,
                countryOptions,
                buttonEnable
        } = this.state;
        console.log(city);
        return (
            <div className="newForm">
                <div className="newForm__header">
                    <img className="newForm__header--img" src={sgImgSmall} alt="" />
                    <div className="newForm__header--para">Let's get you started!</div>
                </div>
                <div className="newForm__label">I am a</div>
                <div className="newForm__iam">
                    {identityArr.map((data,index)=>{
                        return(
                            <div 
                                key={index} 
                                className="newForm__iam--para" 
                                onClick={()=>{this.setIdentity(index)}}
                                style={activeIdentity === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE"}:{}}
                            >
                                {data.name}
                            </div>
                        )
                    })}
                </div>
                <div className="newForm__label">Your Name</div>
                <input className="newForm__input" style={isNameError ? {border:"1px solid #e50303",background:"#FFEFEF"}:{}} placeholder="Enter Your Name" onChange={this.setName} onBlur={this.nameOnBlur} value={name} />
                {isNameError ? <span className="newForm__input--error">Your name is incorrect</span> : ""}
                <div className="newForm__label">Mobile Number</div>
                <div className="newForm__inputDiv" style={isMobileError ? {border:"1px solid #e50303",background:"#FFEFEF"}:{}}>
                    <div className="newForm__inputDiv--code">+91</div>
                    <input className="newForm__inputDiv--input" style={isMobileError ? {background:"#FFEFEF"}:{}} type="number" value={mobile} placeholder="Enter Mobile Number" onChange={this.setMobile} onBlur={this.mobileOnBlur} />
                </div>
                {isMobileError ? <span className="newForm__input--error">Your mobile is incorrect</span> : ""}
                <div className="newForm__label">City</div>
                <Select
                    className="newForm__select"
                    value={city.label}
                    onChange={this.setCity}
                    options={countryOptions}
                />
                <button disabled={!buttonEnable} style={buttonEnable?{background:"#009ae0"}:{}} className="newForm__button" onClick={this.submitDetails}>Continue</button>
            </div>
        )
    }
}

export default NewForm;