import React, { Component } from "react";
import whatsappIcon from "../images/whatsapp.svg";
import { buildType, baseUrl } from "../utils/constant";
import { OTPModal } from "./Modal";
import { withRouter } from "react-router-dom";
import { Radio, Dropdown, Item } from "semantic-ui-react";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import VirtualizedSelect from "react-virtualized-select";
import VideoModal from './videoModal'

class Forms extends Component {
  constructor() {
    super();
    this.state = {
      Name: "",
      MobileNumber: "",
      City: "",
      Email: "",
      sessionId: null,
      Identity: buildType === "general" ? "Parent" : "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      success: false,
      otpModalOpen: false,
      utm_term:'',
      utm_content:'',
      utm_keyword:'',
      utm_placement:'',
      countryOptions: [],
      isChecked: true,
      showVideoModal: false,
      fbp: null,
      fbc: null
    };
    this.baseUrl = baseUrl;
  }

  toggleVideoModal = () => {
    this.setState({
      showVideoModal: !this.state.showVideoModal
    })
  }

  componentDidMount = () => {
    const vars = {};
    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    this.getFbpAndFbc()
    // this.handleCityApi();
    this.handleStateApi();
    this.setState({
      utm_source: vars["utm_source"],
      utm_medium: vars["utm_medium"],
      utm_campaign: vars["utm_campaign"],
      utm_content: vars["utm_content"],
      utm_term: vars["utm_term"],
      utm_keyword: vars["utm_keyword"],
      utm_placement: vars["utm_placement"],
    });
  };

  nameChange = (e) => {
    this.setState({
      Name: e.target.value,
    });
  };

  closeOtpModal = () => {
    this.setState({ otpModalOpen: false });
  };

  closeResponseModal = () => {
    this.setState({ responseModalOpen: false });
    this.clearFormData();
  };

  parseAPIData = (data) => {
    let options = data.map((el) => ({
      key: el.id,
      text: el.city,
      value: el.id,
    }));
    // console.log('options', options);
    return options;
  };
  handleStateApi = () => {
    fetch(
      //'http://sales-incentive.classplus.co/newapi/saveonlineleads.php',
      "https://crm.classplus.co/su/affiliate/state?state=1", // for city data use =>  "/state?city=1"
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(payload)
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let countries = data.data.states;
        // console.log(countries,"sss")
        let countryOptions = countries.map((obj, ind) => {
          return {
            key: ind,
            label: obj,
            // value: obj.value,
          };
        });
        // console.log(countryOptions,"PPP")
        this.setState({ countryOptions: countryOptions });
      })
      .catch((err) => alert("Please Enter Valid Details"));
  };

  // handleCityApi = () => {
  //   fetch(
  //     //'http://sales-incentive.classplus.co/newapi/saveonlineleads.php',
  //     "https://crm.classplus.co/su/affiliate/state?city=1",
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       // body: JSON.stringify(payload)
  //     }
  //   )
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       let countries = this.parseAPIData(data.data.city);
  //       let countryOptions = countries.map((obj) => {
  //         return {
  //           key: obj.key,
  //           label: obj.text,
  //           value: obj.value,
  //         };
  //       });
  //       this.setState({ countryOptions: countryOptions });
  //     })
  //     .catch((err) => alert("Please Enter Valid Details"));
  // };

  mobileChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length <= 10) {
        this.setState({
          MobileNumber: e.target.value,
        });
      }
    }
  };
  // city is putted in email. As per api demand.
  cityChange = (e, data) => {
    let selectedCity = this.state.countryOptions.filter((country) => {
      return country.value === data.value;
    })[0];
    // console.log(selectedCity);
    // let city = '';
    // this.state.countryOptions.forEach((val, ind) => {
    // 	if (data.value == ind + 1) {
    // 		city = val.text;
    // 	}
    // });
    this.setState({
      City: selectedCity,
    });
  };

  emailChange = (e) => {
    this.setState({
      Email: e.target.value,
    });
  };

  identityChange = (e) => {
    this.setState({
      Identity: e.target.value,
    });
  };

  handleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  checkValidityOfForm = () => {
    let isFormValid = true;
    var emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

    if (this.state.MobileNumber.length < 10) {
      alert("Please Enter valid 10 digit mobile number");
    }

    if (this.state.Name === "") {
      alert("Please enter your name");
    }

    if (buildType === "tutor" && this.state.City === "") {
      alert("Please enter your city");
    }

    if (buildType != "tutor" && this.state.Email === "") {
      alert("Please enter your email");
    }

    if (
      buildType != "tutor" &&
      this.state.Email != "" &&
      !emailRegex.test(this.state.Email)
    ) {
      alert("Please enter valid email ");
    }

    if (this.state.Identity.length === 0) {
      alert("Please select your profession");
    }

    if (
      this.state.MobileNumber.length < 10 ||
      this.state.Name === "" ||
      this.state.Identity.length === 0
    ) {
      isFormValid = false;
    } else {
      isFormValid = true;

      if (buildType === "tutor") {
        if (this.state.City === "") {
          isFormValid = false;
        }
      } else {
        if (this.state.Email === "" || !emailRegex.test(this.state.Email)) {
          isFormValid = false;
        }
      }
    }

    return isFormValid;
  };

  handleOtpScenario = () => {
    let payload = {
      mobile: this.state.MobileNumber,
      countryCode: "91",
    };

    fetch(`https://${this.baseUrl}/v2/myCoachingApp/getOtp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ sessionId: res.data.sessionId });
        if (!this.state.otpModalOpen) {
          this.setState({ otpModalOpen: true });
        }
      })
      .catch((err) => alert("Something went wrong! Try again later"));
  };

  handleNormalScenario = () => {
    // const body = { ...this.state, appointmentDate: "" };
    // fetch('https://hooks.zapier.com/hooks/catch/4460305/vr6ciu/', {
    //     method: 'POST',
    //     headers: {
    //         // "Content-Type": 'application/json',
    //     },
    //     body: JSON.stringify(body),
    // }).then(
    //     //  res => console.log(res, 'palash reesp')

    //     this.successPopUp(),
    //     this.clearFormData()

    // )
    //     .catch(err => alert("Please Enter Valid Details")
    //     );

    let payload = {
      type: "tutor_lead",
      coachingName: this.state.Name,
      city: this.state.City.label,
      state: this.state.City.label,
      Identity: this.state.Identity,
      mobileNumber: this.state.MobileNumber,
      campaignName: this.state.utm_campaign,
      utmSource: this.state.utm_source,
      utm_content: this.state["utm_content"],
      utm_term: this.state["utm_term"],
      utm_keyword: this.state["utm_keyword"],
      utm_placement: this.state["utm_placement"],
      isOptIn: this.state.isChecked ? 1 : 0,
      fbc: this.state.fbc,
      fbp: this.state.fbp,
    };
    fetch("https://crm.classplus.co/su/website", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(
        //  res => console.log(res, 'palash reesp')

        this.handleFBConversion(),
        this.successPopUp(),
        this.clearFormData()
        //  window.open("https://rzp.io/l/fx6hpIY")
      )
      .catch((err) => alert("Please Enter Valid Details"));
  };

  formData = (e) => {

    e.preventDefault();

    if (this.checkValidityOfForm()) {
      if (buildType == "tutor") {
        this.handleNormalScenario();
      } else {
        this.handleOtpScenario();
      }
      setTimeout(
        () => this.toggleVideoModal(), 2000
      )
    }
  };

  handleFormSubmit = () => {
    let payload = {
      type: this.state.Identity.toLowerCase(),
      name: this.state.Name,
      email: this.state.Email,
      countryCode: "91",
      mobile: this.state.MobileNumber,
      utm_source: this.state.utm_source,
      utm_medium: this.state.utm_medium,
      utm_campaign: this.state.utm_medium,
      isOptIn: this.state.isChecked ? 1 : 0,
    };
    fetch(`https://${this.baseUrl}/v2/myCoachingApp/getApp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // this.setState({ responseModalOpen: true });
        this.props.history.push(
          `/thankyou/${payload.type === "tutor" ? "teacher" : payload.type}`
        );
      })
      .catch((err) => {
        alert("Please Enter Valid Details");
      });
  };

  otpFormSubmit = (otpData) => {
    let payload = {
      mobile: this.state.MobileNumber,
      countryCode: "91",
      otp: otpData,
      sessionId: this.state.sessionId,
    };
    fetch(`https://${this.baseUrl}/v2/myCoachingApp/verifyOtp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "failure") {
          alert(res.message);
          return;
        }
        this.setState({ otpModalOpen: false });
        this.handleFormSubmit();
      })
      .catch((err) => alert("Please Enter Correct Otp"));
  };

  handleFBConversion = () => {
    const { Identity, MobileNumber } = this.state;


    if (Identity == 'Tutor') {
      window.fbq('track', 'Lead', { content_name: MobileNumber });
      window.obApi('track', 'SignUp');

    }

  }
  successPopUp = () => {

    window.gtag("event", "conversion", {
      send_to: "AW-825420150/DJosCP2HrXoQ9tLLiQM",
    });
    window.gtag("event", "submit", {
      event_category: "form",
      event_label: "book demo",
    });

    // if (window.innerWidth < 750) {
    //   setTimeout(() => {
    //     window.location.href = "https://classplusapp.com/extraclass-poster"
    //   }, 1000);
    // } else {
      this.setState({
        success: true,
      });
    // }


  };

  clearFormData = () => {
    this.setState({
      Name: "",
      MobileNumber: "",
      City: "",
      Email: "",
      Identity: "",
    });
  };

  handleIdentityChange = (e, { value }) => this.setState({ Identity: value });

  getFbpAndFbc = () => {
    try {
      const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]);
      const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]);
      this.setState({ fbc: fbc && fbc[0], fbp: fbp && fbp[0] })
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    let { otpModalOpen, responseModalOpen } = this.state;

    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          {this.state.success ? (
            <div className="successPopup successOut">
              <div className="successPopup__content">
                We have registered your response successfully and will get back
                to you soon Thank you!
              </div>
            </div>
          ) : (
            <div />
          )}
          <form className="form">
            <input
              type="text"
              className="form__input"
              placeholder={
                buildType === "tutor"
                  ? "Your Institute's Name *"
                  : "Your Name *"
              }
              required
              onChange={this.nameChange}
              value={this.state.Name}
            />
            <input
              type="text"
              className="form__input"
              placeholder="Your Mobile Number *"
              required
              onChange={this.mobileChange}
              value={this.state.MobileNumber}
            />

            {buildType === "tutor" && (
              <div className="flexrow">
                {/* <input
                  type="text"
                  className="form__input form__input--2"
                  placeholder="Your city *"
                  required
                  onChange={this.cityChange}
                  value={this.state.City}
                /> */}
                {/* <Dropdown
                  style={{ border: '1px solid #979797' }}
                  className="form__input form__input--2"
                  placeholder="Your city *"
                  fluid
                  search
                  selection
                  options={this.state.countryOptions}
                  onChange={this.cityChange}
                  value={Object.keys(this.state.City).length ? this.state.City.key : ''}
                /> */}
                <VirtualizedSelect
                  className="cityDropdown"
                  style={{ border: "none", marginTop: "4px" }}
                  placeholder="Your State *"
                  clearable={false}
                  maxHeight={150}
                  options={this.state.countryOptions}
                  onChange={(City) => this.setState({ City })}
                  value={this.state.City}
                />

                <select
                  className="select-css"
                  required
                  onChange={this.identityChange}
                  value={this.state.Identity}
                >
                  <option value="" disabled defaultValue>
                    I am a*
                  </option>
                  <option value="Tutor">Tutor</option>
                  <option value="Student">Student</option>
                  <option value="Parent">Parent</option>
                </select>
              </div>
            )}

            {buildType === "general" && (
              <React.Fragment>
                <input
                  type="email"
                  className="form__input"
                  placeholder="Your Email *"
                  required
                  onChange={this.emailChange}
                  value={this.state.Email}
                />
                <div className="flexrow formRadioGroup">
                  <Radio
                    label="Parent"
                    name="userType"
                    className="radioButton"
                    value="Parent"
                    checked={this.state.Identity === "Parent"}
                    onChange={this.handleIdentityChange}
                  />
                  <Radio
                    label="Student"
                    name="userType"
                    value="Student"
                    className="radioButton"
                    checked={this.state.Identity === "Student"}
                    onChange={this.handleIdentityChange}
                  />
                  <Radio
                    label="Teacher"
                    name="userType"
                    value="Tutor"
                    className="radioButton"
                    checked={this.state.Identity === "Tutor"}
                    onChange={this.handleIdentityChange}
                  />
                </div>
              </React.Fragment>
            )}

            <label>
              <input
                type="checkbox"
                defaultChecked="checked"
                checked={this.state.isChecked}
                onChange={this.handleCheck}
              ></input>
              <span className="checkmark">
                I agree to receive messages and notifications on WhatsApp
              </span>
            </label>

            <button
              type="submit"
              className="btn btn--bookADemo form__button"
              onClick={this.formData

              }
            >
              {buildType === "tutor" ? "Book a Free Demo" : "Get Your Free App"}
            </button>
            {window.screen.width > 600 ? (
              ""
            ) : (
              <div
                style={{
                  borderBottom: "1px dashed #E5E5E5",
                  marginTop: "2rem",
                }}
              ></div>
            )}
            {window.screen.width > 600 ? (
              ""
            ) : (
              <button
                type="button"
                className="btn btn--bookADemo form__button"
                onClick={() => {
                  window.location.href = "tel:07948060430";
                }}
                style={{ backgroundColor: 'grey', boxShadow: 'none' }}
              >
                Call us directly on 079-480-60430
              </button>
            )}

            {this.state.showVideoModal && <VideoModal toggleVideo={this.toggleVideoModal} embedLink="wmgGv6TrJcs" />}

            {
              //  buildType != "tutor" && window.innerWidth < 480 && this.props.source === "contact" ? (
              //   <div>
              //     <hr></hr>
              //     <div className="whatsappBtn form__button btn btn--bookADemo">
              //       <a
              //         href="https://api.whatsapp.com/send?phone=917290085264&text=Hi Classplus, I would like to know more about your Complete Coaching Solution"
              //         target="_blank"
              //         className="whatsappAnchor"
              //       >
              //         <img
              //           alt=""
              //           src={whatsappIcon}
              //           style={{ height: "20px", width: "20px" }}
              //         />
              //         <p style={{ paddingLeft: "8px" }}>
              //           {" "}
              //           Talk to us immediately{" "}
              //         </p>
              //       </a>
              //     </div>
              //   </div>
              // ) : (
              //   ""
              // )      
            }
          </form>
        </div>
        {otpModalOpen && (
          <OTPModal
            isOpen={otpModalOpen}
            onCloseHandler={this.closeOtpModal}
            formSubmit={this.otpFormSubmit}
            resendOtp={this.handleOtpScenario}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Forms);
