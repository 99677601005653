import React, { useEffect, useState } from "react";
import Form from "./Form";
import whatsappIcon from "../images/whatsapp.svg";
import { buildType } from "../utils/constant";

// import whatsappCTA from "../images/whatsappCTA.svg";

export const Contact = () => {
  const [utm_source, updateSource] = useState("");
  const [utm_medium, updatemedium] = useState("");
  const [utm_campaign, updatecampaign] = useState("");

  useEffect(() => {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value;
    });

    updateSource(vars["utm_source"]);
    updatemedium(vars["utm_medium"]);
    updatecampaign(vars["utm_campaign"]);
  }, []);

  let param1, param2, param3;
  param1 =
    utm_source && utm_source.length > 0 ? `&utm_source=${utm_source}` : "";
  param2 =
    utm_medium && utm_medium.length > 0 ? `&utm_medium=${utm_medium}` : "";
  param3 =
    utm_campaign && utm_campaign.length > 0
      ? `&utm_campaign=${utm_campaign}`
      : "";

  let whatsAppUrl = `https://api.whatsapp.com/send?phone=918588809864&text=Hi%20Classplus${param1}${param2}${param3}`;
  console.log(whatsAppUrl, "whatsAppUrl2");

  return (
    <div className="Contact" id="second">
      <div className="Contact--text">
        <div className="Contact__heading heading--mega">Get Started Now!</div>
        <div className="Contact__content p--medium green--text">
          Experienced and Trusted by 5,00,000+ People across India. <br />{" "}
          Request a free demo to see how our products can help you boost your
          coaching institute.
        </div>
      </div>

      {/* {buildType != "tutor" && ( */}
     {/* {window.screen.width>700?  */}
     <div className="Contact--form">
        {" "}
        <Form source="contact" />
      </div>
      {/* :
      <div className="whatsappBtn btn btn--bookADemo form__button" 
          style={{width:"94%",justifyContent:"center",margin:"auto",alignItems:"center",
                  marginTop:"24px",borderRadius:"4px",boxShadow:"none"}}>
          <a  href="https://wa.me/918588809864?text=Hi+Classplus" 
            target="_blank" target="_blank" className="whatsappAnchor">
            <img
              alt=""
              src={whatsappCTA}
            />
            <span style={{ paddingLeft: "8px" }}>
              Book a Free Demo
            </span>
          </a>
      </div>
      } */}
      {/* )} */}

      {buildType != "tutor" && (
        <div style={{ width: "80%", margin: "1rem auto" }}>
          <hr />
          <div className="whatsappBtn form__button btn btn--bookADemo">
            <a href={whatsAppUrl} target="_blank" className="whatsappAnchor">
              <img
                alt=""
                src={whatsappIcon}
                style={{ height: "20px", width: "20px" }}
              />
              <p style={{ paddingLeft: "8px" }}> Talk to us immediately </p>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
