import React from 'react'

import SVGattendence from '../images/SVGattendence.svg';
import SVGChat from '../images/SVGChat.svg';
import SVGClass from '../images/SVGClass.svg';
import SVGDatabase from '../images/SVGDatabase.svg';
import SVGFees from '../images/SVGFees.svg';
import SVGOnlineTest from '../images/SVGOnlineTest.svg';
import SVGPrivacy from '../images/SVGPrivacy.svg';
import SVGReports from '../images/SVGReports.svg';




export const FeaturesList = () => {
    return (
        
                
                <div className="features__content--list grid--3">
                    <div className="features__content--list--item">
                        <img src={SVGattendence} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Smart Attendance
                        </p>
                        <p className="features__content--list--item--content p--small ">
                        Keep parents updated with live attendance and monthly reports.
                        </p>

                    </div>
                    <div className="features__content--list--item">
                        <img src={SVGReports} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Insighful Student Reports
                        </p>
                        <p className="features__content--list--item--content p--small">
Personalised performance reports <br/> for all your students 
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={SVGChat} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Parent Communication Module
                        </p>
                        <p className="features__content--list--item--content p--small">
Chat with parents, anytime, anywhere. Disable chat when you're busy
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={SVGFees} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Fee Records
                        </p>
                        <p className="features__content--list--item--content p--small">
Automatic reminders and receipts for instalments of students' due fees
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={SVGPrivacy} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Privacy Control
                        </p>
                        <p className="features__content--list--item--content p--small">
Control what info your faculties and admin can view for your batches
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={SVGOnlineTest} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Online Test
                        </p>
                        <p className="features__content--list--item--content p--small">
Conduct your online tests powered with automatic checking and solutions.
                        </p>

                    </div>

                    <div className="features__content--list--item">
                        <img src={SVGDatabase} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Data Security
                        </p>
                        <p className="features__content--list--item--content p--small">
Your data is secured using SSL AES 256-bit encryption
                        </p>

                    </div>
                    <div className="features__content--list--item">
                        <img src={SVGClass} alt="circled" className="features__content--list--item--img" />
                        <p className="features__content--list--item--heading green--text">
                            Class Management
                        </p>
                        <p className="features__content--list--item--content p--small">
Forget tons of paperwork and manage your institute completely digitally 
                        </p>

                    </div>
                </div>
          
    )
}

export default FeaturesList