import React from "react";
import { Button } from "semantic-ui-react";

export default function ThankYou(props) {
  let { userType } = props.match.params;
  function shareViaWhatsapp() {
    let messageText;
    if (userType === "parent")
      messageText =
        "Dear teacher, You can now teach your students online by registering as “Teacher” in this link - https://bit.ly/2yPgbvG";
    if (userType === "student")
      messageText =
        "Dear teacher, You can now teach your students online by registering as “Teacher” in this link - https://bit.ly/2yKCtyt";

    let url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      messageText
    )}`;
    // sharing logic here
    window.open(url, "_blank");
  }

  return (
    <div className="tnc" style={{ padding: "0 0 8rem 0" }}>
      <div className="tnc__heading">Thank you</div>
      <div className="policy">
        <div className="policy__content">
          {userType === "teacher" && (
            <div>
              <p className="p--medium " style={{ fontSize: "2.4rem" }}>
                You will be receiving a message with the app link
                shortly
              </p>
            </div>
          )}
          {userType === "parent" && (
            <div>
              <p className="p--medium " style={{ fontSize: "2rem" }}>
                Welcome to Classplus!
              </p>
              <p className="p--medium " style={{ fontSize: "2.4rem" }}>
                We have made sure that your kid’s learning is secure. Share now
                with your kid’s teacher, so that he can start teaching
                instantly.
              </p>
            </div>
          )}
          {userType === "student" && (
            <div>
              <p className="p--medium " style={{ fontSize: "2rem" }}>
                Welcome to Classplus!
              </p>
              <p className="p--medium " style={{ fontSize: "2.4rem" }}>
                We have made sure that your learning is secure. Share now with
                your teacher, so that he can start teaching instantly.
              </p>
            </div>
          )}
        </div>

        <div className="policy__content">
          {userType !== "teacher" && (
            <Button
              style={{ fontSize: "2.6rem" }}
              color="green"
              icon="whatsapp"
              size="huge"
              content="Share via Whatsapp"
              onClick={shareViaWhatsapp}
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
}
