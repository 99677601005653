import React, { Component } from "react";
import Slider from "react-slick";
import Institute1 from "../images/logos/institute-1-min.webp";
import Institute2 from "../images/logos/institute-2-min.webp";
import Institute3 from "../images/logos/institute-3-min.webp";
import Institute4 from "../images/logos/institute-4-min.webp";
import Institute5 from "../images/logos/institute-5-min.webp";
import Institute6 from "../images/logos/institute-6-min.webp";
import Institute7 from "../images/logos/institute-7-min.webp";
import Institute8 from "../images/logos/institute-8-min.webp";
import Institute9 from "../images/logos/institute-9-min.webp";
//import Institute10 from '../images/logos/institute-10-min.webp';
import Institute11 from "../images/logos/institute-11-min.webp";
import Institute12 from "../images/logos/institute-12-min.webp";
//import Institute13 from '../images/logos/institute-13-min.webp';
import Institute14 from "../images/logos/institute-14-min.webp";
import Institute15 from "../images/logos/institute-15-min.webp";
import Institute16 from "../images/logos/institute-16-min.webp";
import Institute17 from "../images/logos/institute-17-min.webp";
import Institute18 from "../images/logos/institute-18-min.webp";
import Institute19 from "../images/logos/institute-19-min.webp";
import Institute20 from "../images/logos/institute-20-min.webp";
import Institute21 from "../images/logos/institute-21-min.webp";
import Institute22 from "../images/logos/institute-22-min.webp";
import Institute23 from "../images/logos/institute-23-min.webp";
import Institute24 from "../images/logos/institute-24-min.webp";
import Institute25 from "../images/logos/institute-25-min.webp";
import Institute26 from "../images/logos/institute-26-min.webp";
import Institute27 from "../images/logos/institute-27-min.webp";
import Institute28 from "../images/logos/institute-28-min.webp";
import Institute29 from "../images/logos/institute-29-min.webp";
//import Sivag from '../images/logos/sivag.png'; 
export class InstitutesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ width: window.innerWidth });
  }

  listItems = () => {
    const width = this.state.width;

    if (width > 600) {
      return 8;
    }
    if (width > 480 && width < 600) {
      return 5;
    } else if (width > 320 && width < 480) {
      return 3;
    } else if (width < 320) {
      return 2;
    }
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: this.listItems(),
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 0,
      cssEase: "linear"
    };

    return (
      <div className="Institutes--container">
        <div className="Institutes--list">
          <Slider {...settings}>
            <span className="Institutes--list--profile ">
              <img
                src={Institute1}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">AV Smart Study</span>
                <span className="p--small green--text--2">Ghaziabad</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute2}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Adhyatmn</span>
                <span className="p--small green--text--2">Kota</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute3}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Tulsan Education Hub</span>
                <span className="p--small green--text--2"> Bhopal</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute4}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">VS Education</span>
                <span className="p--small green--text--2">Bhopal</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute5}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Kalhana Academy</span>
                <span className="p--small green--text--2"> Bhopal</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute6}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">AIMS TUTORIALS</span>
                <span className="p--small green--text--2"> Chandigarh</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute7}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">
                  RKG Institute by CA. Parag Gupta
                </span>
                <span className="p--small green--text--2"> Noida</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute8}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">CG tutorial</span>
                <span className="p--small green--text--2"> Indore</span>
              </span>
            </span>

            {
              //                          <span className="Institutes--list--profile ">
              //                         <img src={Sivag} alt="Institute Logo" className="Institutes--list--profile--img" />
              //                          <span className="flexColumn Institutes--list--profile--text" >
              //                             <span className="p--small">Sivag </span>
              //                             <span className="p--small green--text--2">Ghaziabad
              // </span>
              //                          </span>
              //                      </span>
            }

            <span className="Institutes--list--profile ">
              <img
                src={Institute9}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Vidhyoday</span>
                <span className="p--small green--text--2"> Bhopal</span>
              </span>
            </span>

            {
              //    <span className="Institutes--list--profile ">
              //     <img src={Institute10} alt="Institute Logo" className="Institutes--list--profile--img" />
              //     <span className="flexColumn Institutes--list--profile--text" >
              //         <span className="p--small">Minerva Institute</span>
              //         <span className="p--small green--text--2">Indore</span>
              //     </span>
              // </span>
            }
            <span className="Institutes--list--profile ">
              <img
                src={Institute11}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Net-Jrf</span>
                <span className="p--small green--text--2">Jaipur</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute12}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Sudhanshu's JEE tutorials</span>
                <span className="p--small green--text--2"> Kanpur</span>
              </span>
            </span>

            {
              //     <span className="Institutes--list--profile ">
              //     <img src={Institute14} alt="Institute Logo" className="Institutes--list--profile--img" />
              //     <span className="flexColumn Institutes--list--profile--text" >
              //         <span className="p--small">NIFA Classes - A Factory of Doctors & Engineers</span>
              //         <span className="p--small green--text--2">Kanpur</span>
              //     </span>
              // </span>
            }

            <span className="Institutes--list--profile ">
              <img
                src={Institute14}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">
                  Krishna Pradeep 21st Century IAS
                </span>
                <span className="p--small green--text--2">Hyderabad</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute15}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">GREAT ACADEMIC HEIGHTS</span>
                <span className="p--small green--text--2">Hyderabad</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute16}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">CAPITAL TRAINERS</span>
                <span className="p--small green--text--2">Hyderabad</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute17}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Iquest</span>
                <span className="p--small green--text--2">Noida</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute18}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Mastermind Academy </span>
                <span className="p--small green--text--2">Gurgaon</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute19}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Cambridge Group</span>
                <span className="p--small green--text--2">Gurgaon</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute20}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">TRANSITION Classes Gwalior</span>
                <span className="p--small green--text--2">Gwalior</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute21}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">School Toppers</span>
                <span className="p--small green--text--2">Mumbai</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute22}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Mainline Academy</span>
                <span className="p--small green--text--2"> Nagpur</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute23}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">ESMC</span>
                <span className="p--small green--text--2">Patna</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute24}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">NCI</span>
                <span className="p--small green--text--2">Patna</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute25}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Career Achievers</span>
                <span className="p--small green--text--2">Patna</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute26}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Parwaz</span>
                <span className="p--small green--text--2">Ranchi</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute27}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">Genius Academy </span>
                <span className="p--small green--text--2">Ropar</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute28}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">SKYLARK CAREER INSTITUTE</span>
                <span className="p--small green--text--2">Varanasi</span>
              </span>
            </span>

            <span className="Institutes--list--profile ">
              <img
                src={Institute29}
                alt="Institute Logo"
                className="Institutes--list--profile--img"
              />
              <span className="flexColumn Institutes--list--profile--text">
                <span className="p--small">SSP Classes</span>
                <span className="p--small green--text--2">Vadodara</span>
              </span>
            </span>
          </Slider>
        </div>
      </div>
    );
  }
}

export default InstitutesList;
