import React from 'react'
import Carousel from './Carousel';
import FeaturesList from './FeaturesList';
//import {Link} from 'react-router-dom';


export const Features = () => {
    return(
        <div className="features">
            <div className="features__header">
                <div className="features__header--heading heading--mega">
                Building Products which make you Future Ready
                </div>
                <div className="features__header--content p--medium green--text">
                Our mission is to bring each utility needed by you to grow bigger and faster, on one single platform.
                </div>
            </div>
            <div className="features__content">
                <Carousel />
                <FeaturesList />
            </div>
            {
            //     <div className="features__link">
            //     <Link to="/features">Learn more about Classplus features</Link>
            // </div>
        }
        </div>
    )
}

export default Features