import React, { Component } from 'react';
import Select from 'react-select';
import progressFull from "../images/progressFull.png";

class TutorForm2 extends Component {
    constructor() {
        super();
        this.state = {
            ownCoachingApp: "",
            needApp:"",
            appBudget:"",
            coachingAppData: [
                {para:"Yes"},{para:"No"},
            ],
            needData: [
                {para:"In This Week"},{para:"In This Month"},{para:"Not Decided Yet"},{para:"I am Just Exploring"},
            ],
            appBudgetData : [
                {para:"1000 - 5000"},{para:"5000 - 10,000"},{para:"10,000 - 25,000"},{para:"25,000+"},
            ],
            activeOwnCoaching:"",
            activeNeedApp:"",
            activeAppBudget:"",
            buttonEnable: false,
        }
    }

    setOwnCoachingApp = (option) => {
        this.setState({activeOwnCoaching:option},() => { 
            this.setState({ownCoachingApp:this.state.coachingAppData[this.state.activeOwnCoaching].para},()=>{this.validation()});
        });
    }

    setNeedApp = (option) => {
        this.setState({activeNeedApp:option},() => { 
            this.setState({needApp:this.state.needData[this.state.activeNeedApp].para},()=>{this.validation()});
        });
    }

    setAppBudget = (option) => {
        this.setState({activeAppBudget:option},() => { 
            this.setState({appBudget:this.state.appBudgetData[this.state.activeAppBudget].para},()=>{this.validation()});
        });
    }

    validation = () => {
        const { 
            ownCoachingApp,
            needApp, 
            appBudget
        } = this.state;
        
        if(ownCoachingApp === "Yes"){
            if(appBudget !== "" && 
               ownCoachingApp !== "" && 
               needApp !== ""
            ) {
                this.setState({buttonEnable: true});
            }else{
                this.setState({buttonEnable: false});
            }
        }else{
            this.setState({buttonEnable: true}); 
        }
    }

    submitDetails = () => {
        const {
            type,number,name,city,line_of_teaching,student_count,mode
          } = this.props;
          console.log(line_of_teaching,student_count,mode)
        const { 
            ownCoachingApp,
            needApp, 
            appBudget
        } = this.state;
        this.props.postDetails(type,number,name,city,line_of_teaching,student_count,mode,ownCoachingApp,needApp,appBudget);
        this.props.setStep(1);
        this.props.toggleSuccessModal();
    }

    render(){
        const { needData, coachingAppData, activeOwnCoaching, activeNeedApp, appBudgetData, activeAppBudget, buttonEnable, ownCoachingApp } = this.state;
        return (
            <div className="TutorForm">
                <img className="TutorForm__progress" src={progressFull} alt="" />
                <div className="TutorForm__header">I am a Tutor</div>
                <div className="TutorForm__label">Are you looking to start using your own online coaching app?</div>
                <div className="TutorForm__iam">
                    {coachingAppData.map((data,index)=>{
                        return(
                            <div 
                                style={activeOwnCoaching === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE",width:"35%"}:{width:"35%"}}
                                className="TutorForm__iam--para" 
                                onClick={()=>{this.setOwnCoachingApp(index)}}
                            >{data.para}</div>
                        )
                    })}
                </div>
                {ownCoachingApp === "Yes" ? 
                <React.Fragment>
                    <div className="TutorForm__label">How urgently do you need this?</div>
                    <div className="TutorForm__iam">
                        {needData.map((data,index)=>{
                            return(
                                <div 
                                    style={activeNeedApp === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE"}:{}}
                                    className="TutorForm__iam--para" 
                                    key={index} 
                                    onClick={()=>{this.setNeedApp(index)}}
                                >{data.para}</div>
                            )
                        })}
                    </div>
                    <div className="TutorForm__label">What's your Approx. Budget for your Own coaching App ( Price in ₹)</div>
                    <div className="TutorForm__iam">
                        {appBudgetData.map((data,index)=>{
                            return(
                                <div 
                                    style={activeAppBudget === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE"}:{}}
                                    className="TutorForm__iam--para" 
                                    key={index} 
                                    onClick={()=>{this.setAppBudget(index)}}
                                >{data.para}</div>
                            )
                        })}
                    </div>
                </React.Fragment> 
                : "" }
                <button className="TutorForm__button" onClick={this.submitDetails} disabled={!buttonEnable} style={buttonEnable?{background:"#009ae0"}:{}}>Submit</button>
            </div>
        )
    }
}

export default TutorForm2;