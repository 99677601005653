import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import OtpInput from "react-otp-input";

export function OTPModal(props) {
  const [otp, setOtp] = useState();

  function otpHandler(otp) {
    setOtp(otp);
  }

  function submitOtp() {
    if (props.formSubmit) props.formSubmit(otp);
  }

  return (
    <Modal size={"mini"} open={props.isOpen} onClose={props.onCloseHandler}>
      <Modal.Header>Verify OTP</Modal.Header>
      <Modal.Content>
        <OtpInput
          inputStyle={{
            width: "60px",
            height: "60px",
            fontSize: "3em",
            borderRadius: " 4px",
            border: "1px solid rgba(0,0,0,0.3)",
          }}
          isInputNum={true}
          onChange={otpHandler}
          numInputs={4}
          separator={<span className="otp_seperator"> </span>}
          value={otp}
        />
        <p className="resend_otp" onClick={props.resendOtp}>
          Resend OTP
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" size="huge" onClick={submitOtp}>
          Verify OTP
        </Button>
        <Button
          size="huge"
          onClick={() => {
            props.onCloseHandler();
          }}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
