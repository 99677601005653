import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import progressHalf from "../images/progressHalf.png";

class TutorForm1 extends Component {
    constructor() {
        super();
        this.state = {
            selectedStudentCount:"",
            selectedTeachingLine: "",
            selectedTeachingMethod: "",
            studentCountData : [
                {para:"Less Than 30"},
                {para:"30 - 100"},
                {para:"100 - 200"},
                {para:"200 - 500"},
                {para:"500+"},
            ],
            teachingMethodData:[
                {para:"Offline"},{para:"Online"},{para:"Both"}
            ],
            activeStudentCountIndex:"",
            activeTeachingMethod:"",
            buttonEnable: false,
            lineOfTeachingOptions : [
                { name: 'K1-K12 (Classes 1-12)', id:1 },
                { name: 'College Internal  /  (BA Bcom)', id:2 },
                { name: 'Govt. Jobs Exams', id:3 },
                { name: 'Test Preparation - Post Grad ', id:4 },
                { name: 'Test Preparation - Under Grad', id:5 },
                { name: 'Skill Coaching (Vocational, etc.)', id:6 },
            ],
            selectedValues: [

            ]
        }
    }

    setTeachingLine = selectedTeachingLine => {
        this.setState({ selectedTeachingLine },()=>{this.validation()});
        console.log(`Option selected:`, selectedTeachingLine);
    };

    setStudentCount = (index) => {
        this.setState({activeStudentCountIndex:index},() => { 
            this.setState({selectedStudentCount:this.state.studentCountData[this.state.activeStudentCountIndex].para},()=>{this.validation()});
        });
    }

    setTeachingMethodData = (index) => {
        this.setState({activeTeachingMethod:index},() => { 
            this.setState({selectedTeachingMethod:this.state.teachingMethodData[this.state.activeTeachingMethod].para},()=>{this.validation()});
        });
    }

    onSelect = (selectedList, selectedItem) => {
        this.state.selectedValues.push(selectedItem.name);
    }
    
    onRemove = (selectedList, removedItem) => {
        var tempArray = this.state.selectedValues;
        const index = tempArray.indexOf(removedItem.name);
        if (index > -1) {
            tempArray.splice(index, 1);
            this.setState({selectedValues:tempArray})
        }
    }

    validation = () => {
        const { 
            selectedValues,
            selectedTeachingMethod, 
            selectedStudentCount
        } = this.state;
        if(selectedStudentCount !== "" && 
           selectedValues.length !== 0 && 
           selectedTeachingMethod !== ""
        ) {
            this.setState({buttonEnable: true});
        }else{
            this.setState({buttonEnable: false});
        }
    }

    gotoNextStep = () => {
        const dummy = ""
        const { 
            selectedValues,
            selectedTeachingMethod, 
            selectedStudentCount
        } = this.state;
        const {
            type,number,name,city
        } = this.props;
        this.props.postDetails(type,number,name,city,selectedValues, selectedStudentCount, selectedTeachingMethod);
        this.props.setStep(3,dummy,dummy,dummy,dummy, selectedValues, selectedStudentCount, selectedTeachingMethod);
    }

    render(){
        const mulitpleSeletStyles = {
            multiselectContainer: { },
            searchBox: { 
              border: "",
              fontSize: "",
              minHeight: ""
            },
            inputField: { 
                margin: ""
            },
            chips: { 
                background: "#F5FBFE",
                border: "1px solid #009AE0",
                boxSizing: "border-box",
                borderRadius: "8px",
                color: "#000000"
            },
            optionContainer: {  
              border: "",
            },
            option: { 
                
            },
            groupHeading: { }
          }
        const { 
            selectedTeachingLine,
            studentCountData, 
            teachingMethodData, 
            activeStudentCountIndex, 
            activeTeachingMethod,
            buttonEnable,
            selectedStudentCount, selectedTeachingMethod,
            lineOfTeachingOptions
        } = this.state;
        console.log(selectedTeachingLine.label, selectedStudentCount, selectedTeachingMethod)
        return (
            <div className="TutorForm">
                <img className="TutorForm__progress" src={progressHalf} alt="" />
                <div className="TutorForm__header">I am a Tutor</div>
                <div className="TutorForm__label">What's your Line of Teaching ?</div>
                {/* <Select
                    className="TutorForm__select"
                    value={selectedTeachingLine}
                    onChange={this.setTeachingLine}
                    options={options}
                /> */}
                <Multiselect
                    className="TutorForm__select"
                    options={lineOfTeachingOptions}
                    selectedValues={this.state.selectedValues}
                    showCheckbox={true}
                    displayValue="name"
                    closeIcon={"cancel"}
                    onSelect={this.onSelect}
                    onRemove={this.onRemove}
                    style={mulitpleSeletStyles}
                />
                <div className="TutorForm__label" style={{marginTop:"1rem"}}>How many students do you teach in a month?</div>
                <div className="TutorForm__iam">
                    {studentCountData.map((data,index)=>{
                        return(
                            <div 
                                style={activeStudentCountIndex === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE"}:{}}
                                className="TutorForm__iam--para" key={index} 
                                onClick={()=>this.setStudentCount(index)}
                            >{data.para}</div>
                        )
                    })}
                </div>
                <div className="TutorForm__label">How are you teaching currently?</div>
                <div className="TutorForm__iam2">
                    {teachingMethodData.map((data,index)=>{
                        return(
                            <div 
                                style={activeTeachingMethod === index ? {border:"1px solid #009ae0", color:"#009AE0", background:"#F5FBFE"}:{}}
                                className="TutorForm__iam2--para" 
                                key={index} onClick={()=>{this.setTeachingMethodData(index)}}
                            >{data.para}</div>
                        )
                    })}
                </div>
                <button className="TutorForm__button" disabled={!buttonEnable} style={buttonEnable?{background:"#009ae0"}:{}} onClick={this.gotoNextStep}>Continue to your Requirement</button>
            </div>
        )
    }
}

export default TutorForm1;