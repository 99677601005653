
export let buildType = 'tutor';
export let cityCount;
export let tutorCount;
export let userCount;
export let familyCount;
export let baseUrl;


export const setBuildType = () => {

    buildType = process.env.REACT_APP_VAR ? process.env.REACT_APP_VAR : 'tutor';

    cityCount = buildType === 'tutor' ? '1,100' : '1,100';
    tutorCount = buildType === 'tutor' ? '50,000' : '50,000';
    userCount = buildType === 'tutor' ? '70Lakh' : '70Lakh';
    familyCount = buildType === 'tutor' ? '50,000' : '50,000';


}


export const setBaseUrl = () => {
    const envVar = process.env.REACT_APP_HOST_ENV ? process.env.REACT_APP_HOST_ENV : 'production';

    const stagingUrl = 'wl-api-1.staging.classplus.co'
    const prodUrl = 'api.classplusapp.com'
    const qaUrl = 'qa-testing.classplus.co'
    const preProdUrl = 'apip.classplusapp.com'

    if (envVar === 'staging') {
        baseUrl = stagingUrl;
    } else if (envVar === 'preprod') {
        baseUrl = preProdUrl;
    } else if (envVar === 'qa') {
        baseUrl = qaUrl;
    } else {
        baseUrl = prodUrl
    }
}
